// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui";
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

// If you want to add something do it here
@import "custom";

:root {
   --primary: #321fdb;
   --body: #e4e5e6;
   --container: #fff;
   --text-body: #23282c;
   --border-color: #babfc4;
   --dark-border-color: #cacaca;
   --sidebar-background: #2f353a;
   --nav-link: #73818f;
   --card-header: #f0f3f5;
   --gradient: linear-gradient(121deg, rgb(187, 192, 235) 50%, rgb(240, 240, 240) 100%);
   --gradient-hover: linear-gradient(to right, transparent, transparent, rgb(204, 209, 250), rgb(212, 231, 240));
   --gradient-hover-100: linear-gradient(to right, rgb(204, 209, 250), rgb(212, 231, 240));
   --float-bg: rgba(97, 105, 173, 0.7);
   --gradient-bg: linear-gradient(90deg, rgb(240, 240, 240), rgb(218, 240, 255));
   --page-link-hover: #e4e7ea;
   --input-background: #fff;
   --input-color: #5c6873;
   --input-border: #babfc4;
   --table-strips: rgba(0, 0, 0, 0.05);
   --table-header-borders: rgba(0, 0, 0, 0.05);
   --table-hover: rgba(0, 0, 0, 0.075);
   --reverse: #000;
   --btn-outline-dark-color: #2f353a;
   --btn-outline-secondary-color: #525252;
   --btn-outline-secondary-shadow: unset;
   --btn-outline-secondary-hover: #dadada;
   --ko-image-filter: none;
   --logo-brightness: 1;
   --schedule-options-background: #ECEFF1;
   --currency-color: rgb(151, 40, 40);
   --nav-hover: rgba(0, 0, 0, 0.35);
   --nav-icon: linear-gradient(to right, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
   --minimized-nav-icon-hover: #000;
   --card-shadow: 0 4px 10px rgba(0,0,0,0.075);
   --name-shadowed: none;
   --badge-background: transparent;
   --badge-border: #cccccc;
   --badge-selected-background: #ebeff7;
   --badge-selected-border: #394fb1;
   --badge-selected-color: #394fb1;
   --badge-hover-border: #325899;
   --filters-background: #fff;
   --save-filter-btn: #068a51;
   --tier-info-background: #ECEFF1;
   --yellow-shadow: inset 0px -38px 23px -30px rgb(160, 153, 31);
   --yellow-shadow-hover: inset 0px -50px 23px -30px rgb(160, 153, 31);
   --favorite-icon: #e7bd00;
}

:root .dark-theme {
   --primary: #0d47a1;
   --body: #2d363f;
   --container: #1b242b;
   --text-body: rgb(226, 226, 226);
   --border-color: #2d363f;
   --dark-border-color: #1a1a1a;
   --sidebar-background: #04040f;
   --nav-link: rgb(202, 196, 196);
   --card-header: #040416;
   --gradient-bg: linear-gradient(90deg,#0a0a2d,#060624);
   --page-link-hover: #0f1214;
   --input-background: #596166;
   --input-color: rgb(226, 226, 226);
   --input-border: #1f2429;
   --table-strips: rgba(2, 2, 17, 0.15);
   --table-header-borders: #373a3a;
   --table-hover: rgba(0, 0, 0, 0.25);
   --gradient: linear-gradient(121deg, rgb(104, 54, 151) 0%, rgb(79, 98, 184) 27%, rgb(56, 125, 165) 100%);
   --gradient-hover: linear-gradient(to right, transparent, transparent, rgb(104, 54, 151), rgb(56, 125, 165));
   --gradient-hover-100: linear-gradient(to right, rgb(104, 54, 151), rgb(56, 125, 165));
   --float-bg: rgba(57, 37, 145, 0.7);
   --reverse: #fff;
   --btn-outline-dark-color: #aeb2b6;
   --btn-outline-secondary-color: #c8ced3;
   --btn-outline-secondary-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
   --btn-outline-secondary-hover: #ffffff;
   --ko-image-filter: none;
   --logo-brightness: 100;
   --schedule-options-background: #0a0a1a;
   --currency-color: rgb(196, 88, 88);
   --nav-hover: rgba(255, 255, 255, 0.35);
   --nav-icon: linear-gradient(to right, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15));
   --minimized-nav-icon-hover: #2b2b2b;
   --card-shadow: 0 4px 10px rgba(0,0,0,0.25);
   --name-shadowed: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;
   --badge-background: #3D3D3D;
   --badge-border: #3D3D3D;
   --badge-selected-background: #264d74;
   --badge-selected-border: #6d9ef3;
   --badge-selected-color: #fff;
   --badge-hover-border: #a3c1f3;
   --filters-background: #000;
   --save-filter-btn: #45FFAD;
   --tier-info-background: #131a1e;
   --yellow-shadow: inset 0px -28px 23px -30px rgba(235, 229, 115, 0.75);
   --yellow-shadow-hover: inset 0px -40px 23px -30px rgba(235, 229, 115, 0.75);
   --favorite-icon: #ffd413;
}

.dropdown-list {
   background-color: var(--container) !important;
   color: var(--text-body) !important;
   border-color: var(--border-color) !important;
   box-shadow: 0 1px 5px var(--body) !important;
   .item1{
      li{
         border-bottom: 1px solid var(--border-color) !important;
      }
   }
}

.multiselect-item-checkbox input[type=checkbox] + div {
   color: var(--text-body) !important;
   &::before {
      color: #45FFAD !important;
      border: 2px solid var(--reverse) !important;
   }
   &::after {
      border-color: #000 !important;
   }
}

.multiselect-item-checkbox input[type=checkbox]:checked + div:before {
   background: #45FFAD !important;
   border: 2px solid #45FFAD !important;
}

.multiselect-item-checkbox:hover {
   background-color: transparent;
   background-image: var(--gradient-hover-100);
}

.multiselect-dropdown .dropdown-btn .selected-item {
   border: 1px solid #000 !important;
   background: #000 !important;
}

.select-filter-master .multiselect-dropdown .dropdown-btn .selected-item {
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   max-width: 150px;
 }

option {
   background-color: var(--container) !important;
   color: var(--text-body) !important;
 }

.btn-badge {
   color: var(--reverse);
   border-radius: 100px;
   width: 184px;
   height: 41px;
   background-color: var(--badge-background);
   border: 1px solid var(--badge-border);
   padding: 9px 4px;
   text-align: center;
   cursor: pointer;
   transition: all 0.3s ease-in-out;
   &.selected {
      color: var(--badge-selected-color);
      background-color: var(--badge-selected-background);
      border-color: var(--badge-selected-border);
   }
   &:hover {
      border-color: var(--badge-hover-border);
   }
   &.filter-option {
      width: fit-content;
      padding: 9px 15px;
   }
}

.beta-badge {
   position: absolute;
   top: 13px;
   right: 3px;
}

.blind-beta {
   position: absolute;
   right: 60px;
   top: 12px;
   z-index: 3;
}

.name-shadowed {
   text-shadow: var(--name-shadowed);
}

.btn-primary {
   background-color: #4481eb;
   border: none;
   &:hover {
      background-color: #663997;
   }
}

.bg-primary {
   background-size: 300% 100%;
   background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
   border: none;
}

.btn-success {
   background-color: #45FFAD;
   color: #000;
   border: none;
   &:hover {
      color: #000;
      background-color: #663997;
   }
}

.bg-success {
   background-size: 300% 100%;
   background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
   border: none;
}

.btn-danger {
   background-color: #f7186a;
   border: none;
   color: #fff;
   &:hover {
      background-color: #663997;
   }
}

.bg-danger {
   background-size: 300% 100%;
   background-image: linear-gradient(to right, #ed6ea0, #ec8c69, #f7186a , #FBB03B);
   border: none;
}

.btn-warning {
   background-color: #ef9d43;
   border: none;
   &:hover {
      background-color: #663997;
   }
}

.bg-warning {
   background-size: 300% 100%;
   background-image: linear-gradient(to right, #fc6076, #ff9a44, #ef9d43, #e75516);
   border: none;
}

.btn-dark {
   background-color: #312b46;
   border: none;
   &:hover {
      background-color: #663997;
   }
}

.bg-dark {
   background-size: 300% 100%;
   background-image: linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376);
   border: none;
}

.btn-secondary {
   background-color: #999c9e;
   border: none;
   &:hover {
      color: #000;
      background-color: #663997;
   }
}

.btn-light {
   background-color: #fff;
   border: none;
   &:hover {
      color: #000;
      background-color: #663997;
   }
}

.btn {
   border-radius: 8px;
   cursor: pointer;
   font-weight: bold;
   box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
   transition: all 0.3s ease-in-out;
   &:not(.btn-sm) {
      height: 40px;
   }
}

.shadow-text-dark {
   filter: brightness(1.75);
}

.help-link {
   position: absolute;
   right: 5px;
   top: -15px;
}

.shepherd-text {
   color: #fff;
   text-shadow: 1px 1px 3px black;
   overflow: auto;
}

.shepherd-title {
   color: #fff;
   text-shadow: 1px 1px 3px black;
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
   color: #fff !important;
}

.shepherd-content {
   background: rgb(189,144,250);
   background: linear-gradient(30deg, rgba(189,144,250,1) 0%, rgba(144,142,249,1) 49%, rgba(88,176,234,1) 100%);
}

.shepherd-has-title .shepherd-content .shepherd-header {
   background: rgb(134,91,191);
   background: linear-gradient(30deg, rgba(134,91,191,1) 0%, rgba(96,94,199,1) 49%, rgba(56,138,193,1) 100%);
}

.shepherd-arrow:before {
   background-color: #605ec7 !important;
}

.shepherd-button {
   background: #3d2c52;
}

.shepherd-button:not(:disabled):hover {
   background: #533b70;
}

.ng-select .ng-select-container {
   min-height: 40px;
   border-radius: 8px;
   background: var(--input-background);
   border-color: var(--input-border);
   color: #fff;
   .ng-placeholder {
      top: 8px !important;
   }
}

.ng-select.ng-select-opened>.ng-select-container {
   min-height: 40px;
   border-radius: 8px;
   background: var(--input-background);
   border-color: var(--input-border);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
   background-color: var(--sidebar-background);
   color: var(--text-body);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
   border-right: 1px solid var(--table-header-borders);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
   background-color: var(--table-hover);
}

input[type=text], textarea {
   height: 40px;
   border-radius: 8px;
   background-color: var(--input-background);
   color: var(--input-color);
   border-color: var(--input-border);
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
   height: unset;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input>input {
   color: var(--input-color);
   font-size: 0.875rem;
}

img.navbar-brand-full {
   filter: brightness(var(--logo-brightness));
}

body {
   background-color: var(--body);
   color: var(--text-body);
}

.card {
   background-color: var(--container);
   border: 1px solid var(--border-color);
   border-radius: 0.15rem;
}

.card-header {
   background-color: var(--container);
   border-bottom: 1px solid var(--border-color);
}

.card-header:first-child {
   border-radius: calc(0.15rem - 1px) calc(0.15rem - 1px) 0 0;
}

.card-footer {
   background-color: var(--container);
   border-top: 1px solid var(--border-color);
}

.sidebar {
   background: var(--sidebar-background);
}

.page-link {
   background-color: var(--container);
   border: 1px solid var(--border-color);
   &:hover {
      cursor: pointer;
      background-color: var(--page-link-hover);
      border: 1px solid var(--border-color);
   }
}

.page-item.disabled .page-link {
   color: var(--nav-link);
   background-color: var(--container);
   border-color: var(--border-color);
}

.sidebar .nav {
   width: 205px;
}

.sidebar .sidebar-nav {
   width: 205px;
}

@media (min-width: 992px) {
   .sidebar-fixed .sidebar {
       width: 205px;
   }
}

.sidebar .nav-link {
   border-radius: 0.4rem;
   color: #d3d3d3;
   padding: 0.4rem 0.3rem;
   margin-right: 0.2rem;
   .nav-icon {
      color: #fff;
      width: 28px;
      height: 30px;
      padding: 8px 3px;
      border-radius: 0.3rem;
      background-size: 300% 100%;
      margin: 0 0.3rem 0 0;
      &.sidebar-minimized {
         height: 34px;
      }
   }
   &.tournaments-page, &.schedule-page {
      .nav-icon {
         color: #fff;
         background: transparent;
         box-shadow: 0 2px 4px rgba(0,0,0,0.4);
         background-image: linear-gradient(121deg, rgb(104, 54, 151) 0%, rgb(79, 98, 184) 27%, rgb(56, 125, 165) 100%);
         &:hover {
            background-image: linear-gradient(121deg, rgb(73, 29, 114) 0%, rgb(46, 64, 145) 27%, rgb(28, 92, 129) 100%);
         }
      }
   }
}

//.sidebar .nav-link:hover {
//   background: linear-gradient(121deg, rgb(104, 54, 151) 0%, rgb(79, 98, 184) 27%, rgb(56, 125, 165) 100%);
//}

.sidebar .nav-link:hover {
   background: var(--nav-hover);
}

.sidebar-minimized .sidebar .nav-link .nav-icon:hover {
   background-color: var(--minimized-nav-icon-hover);
}

.sidebar-minimized .sidebar .nav-link {
   background: transparent !important;
   .nav-icon {
      height: 40px;
      width: 40px;
      padding: 11px 3px;
      margin-left: 5px;
   }
}

.nav-title {
   font-weight: bold;
   color: #9b9b9b !important;
   padding-top: 1.6rem !important;
   border-top: 2px solid var(--table-strips);
}

.form-control {
   height: 40px;
   border-radius: 8px;
   color: var(--input-color);
   background-color: var(--input-background);
   border: 1px solid var(--input-border);
}

.form-control:disabled, .form-control[readonly] {
   background-color: var(--page-link-hover);
}

.table {
   color: var(--text-body);
}

.table-striped tbody tr:nth-of-type(odd) {
   background-color: var(--table-strips);
}

.table-hover tbody tr {
   &:not(.no-hover) {
      background-size: 300% 100%;
   }
}

.table-hover tbody tr:hover {
   color: var(--text-body);
   &:not(.no-hover) {
      background-position: 100% 0;
      background-image: var(--gradient-hover);
   }
}

.input-group-text {
   height: 40px;
   border-radius: 8px;
   color: var(--input-color);
   background: var(--card-header);
   border: 1px solid var(--input-border);
   border-right: none;
}

.multiselect-dropdown .dropdown-btn {
   height: 40px;
   border-radius: 8px !important;
   background-color: var(--input-background);
   border: 1px solid var(--input-border) !important;
   padding: 8px 16px 0px 8px !important;
}

.custom-select {
   height: 40px;
   border-radius: 8px;
   color: var(--input-color);
   border: 1px solid var(--input-border);
   background-color: var(--input-background);
}

.form-control::-webkit-input-placeholder {
   color: var(--input-color);
 }
 
 .form-control:-ms-input-placeholder {
   color: var(--input-color);
 }
 
 .form-control::placeholder {
   color: var(--input-color);
 }

 .modal-content {
   background-color: var(--container);
 }

 .modal-header {
   border-bottom: 1px solid var(--border-color);
 }

 .modal-footer {
   border-top: 1px solid var(--border-color);
 }

 .close {
   color: var(--reverse);
 }

 .border {
   border: 1px solid var(--border-color) !important;
 }

 .border-bottom {
   border-bottom: 1px solid var(--border-color) !important;
 }

 .border-top {
   border-top: 1px solid var(--border-color) !important;
 }
 .border-left {
   border-left: 1px solid var(--border-color) !important;
 }
 .border-right {
   border-right: 1px solid var(--border-color) !important;
 }

 .btn-outline-secondary {
   color: var(--btn-outline-secondary-color);
   box-shadow: var(--btn-outline-secondary-shadow) !important;
 }

 .btn-outline-secondary:hover {
   color: #663997 !important;
   border-color: #663997 !important;
   background-color: var(--btn-outline-secondary-hover) !important;
 }

 .btn-secondary {
   color: #23282c !important;
 }

 .btn-outline-dark {
   color: var(--btn-outline-dark-color);
   border-color: var(--btn-outline-dark-color);
 }

 .btn-outline-dark.disabled, .btn-outline-dark:disabled{
   border-color: #2f353a;
 }

 .dropdown-menu {
   color: var(--text-body);
   background-color: var(--container) !important;
   border: 1px solid var(--border-color);
   z-index: 1020;
 }

 .dropdown-item {
   color: var(--text-body);
   background-size: 300% 100%;
   background-image: linear-gradient(to right, transparent, transparent);
   border-bottom: 1px solid var(--border-color);
 }

 .dropdown-item:hover, .dropdown-item:focus {
   color: var(--reverse);
   background-color: transparent;
   background-position: 100% 0;
   background-image: var(--gradient-hover);
   i {
      color: var(--text-body);
   }
 }

 .dropdown-header {
   background: var(--card-header);
   border-bottom: 1px solid var(--border-color);
 }

 img.ko-image {
   filter: var(--ko-image-filter);
 }

.nw {
   white-space: nowrap;
}

.site-logo {
   max-width: 32px;
   margin-right: 14px;
}

// Manage Filter List

.list-title {
   color: var(--input-color);
   background: var(--card-header);
   padding: 14px 14px 14px 28px;
}

.filter-list {
   padding-left: 0px;
   padding-right: 0px;
   /*padding-top: 20px;*/
   padding-bottom: 20px;
}

.filter-list ul {
   padding-left: unset;
}
/*
.filter-list ul li {
   list-style-type: none;
   margin: 21px 12px;
   padding-left: 7px;
}
*/

.filter-list ul li:not(.page-item) {
   list-style-type: none;
    padding: 12px 12px;
    margin-left: 7px;
}

.filter-list.str ul li:not(.page-item) {
   list-style-type: none;
    padding: 7px 7px;
    margin-left: 5px;
}

.filter-list ul li:hover:not(.page-item) {
   border-radius: 0.2rem;
   background-position: 100% 0;
   background-image: var(--gradient-hover-100);
   color: var(--text-body);
}

.filter-add-button {
   margin: 0px 14px;
   padding-left: 7px;
   padding-right: 7px;
   color: #20a8d8;

   /*position: absolute;
   bottom: 35px;*/
}

.filter-add-button i {
   margin-left: 14px;
}

.filter-add-button:hover {
   color: #fff;
   background: #20a8d8;
}

.filter-delete-btn {
   margin: 0px 14px;
   padding-left: 7px;
   padding-right: 7px;
   color: #f86c6b;

   /*position: absolute;
   bottom: 14px;*/
}

.filter-delete-btn i {
   margin-left: 14px;
}

.filter-delete-btn:hover {
   color: #fff;
   background: #f86c6b;
}

.bottom-temp {
   position: absolute;
   bottom: 14px;
}

.edit-icon-td {
   display: flex;
   height: -webkit-fill-available;
   padding: 0px 7px 0px 14px !important;;
   justify-content: center;

    i {
      font-size: 18px;
      align-self: center;
    }
}

.table-header th {
   cursor: pointer;
   //min-width: 140px; /* <<<--- TEMPORARY SOLUTION */

   i {
   float: right;
   color: darkgray;
   font-size: 16px;
     }
 }

 .logo-login {
    width: 100%;
    text-align: center;
 }

 .logo-login img {
   max-width: 200px;;
}

h4.or {
   width: 100%; 
   text-align: center; 
   border-bottom: 1px solid rgb(173, 173, 173); 
   line-height: 0.1em;
   margin: 20px 0 20px; 
   color: #73818f;
   font-size: 14px;
} 

h4 span { 
    background:#fff; 
    padding:0 10px; 
}

// Hide Tournament List Options

.add-to-schedule-hidden {
   visibility: collapse;
}

.add-to-schedule-visible, .add-to-schedule-visible:hover, 
.add-to-schedule-parent, .add-to-schedule-parent:hover {
   visibility: visible;
   /* background-color: red !important; */
}

.add-to-schedule-parent, .add-to-schedule-parent:hover {
   visibility: visible;
   background-color: #20a8d8 !important;
   color: ghostwhite !important;
}

.custom-icon-trash:hover {
   cursor: pointer;
   color: #f64846;
}

.custom-icon-pencil:hover {
   cursor: pointer;
   color: #4dbd74;
}

.app-body{
   overflow: unset !important;
}

.sticky-bar{
   position: sticky;
   top: 55px;
   background: var(--container);
   padding: 10px 0;
   z-index: 1;
   border-bottom: 1px solid var(--border-color);
}

.converted-currency {
   font-size: 11px;
   font-weight: bold;
   color: var(--currency-color);
 }

 .icon-speed.fa-forward {
   color: red;
 }

 .icon-speed.fa-caret-right {
   font-size: 20px;
   color: #34e734;
 }

 .icon-speed.fa-step-forward {
   color: #ffbc40;
 }

.header-container {
   background: var(--container);
   border-bottom: 1px solid var(--border-color);
}

input[type=checkbox]{
   accent-color:  #45FFAD;
   width: 16px;
   height: 16px;
   margin-top: 2px;
}

.sidebar-toggle-button{
   position: unset;
}

@media (min-width: 992px){
   .sidebar-toggle-button{
      z-index:999;
      top: unset !important;
      bottom: unset !important;
   }

   .sidebar-minimized .sidebar .sidebar-nav{
      padding-bottom: unset !important;
      padding-top: 50px !important;
   }

   .sidebar-nav .nav{
      overflow-x: hidden !important;
   }
}

@media (max-width: 991.98px) {
   .app-header .navbar-brand {
     margin-left: 0;
     position: unset;
   }
 }
 
 @media (max-width: 600px) {
   .app-header .navbar-brand {
     display: none;
   }
 }

.modal-request-dialog{
   max-width: 960px;
   width: 100%;
 }

 
 
 @media (min-width: 576px){
   .modal-request-dialog{
     max-width: 960px !important;
     width: 100% !important;
   }
 }

@media (min-width: 1200px) and (max-width: 1399px) {
   .col-xl-22 {
      flex: 0 0 17.9%;
      max-width: 17.9%;
   }
   .col-xl-23 {
      flex: 0 0 19.6%;
      max-width: 19.6%;
   }
   .col-xl-25 {
      flex: 0 0 20.77777777%;
      max-width: 20.77777777%;
   }
   .col-xl-27 {
      flex: 0 0 22.1%;
      max-width: 22.1%;
   }
   .col-xl-45 {
      flex: 0 0 37.5%;
      max-width: 37.5%;
   }
}

.col-55 {
   position: relative;
   width: 100%;
   padding-right: 15px;
   padding-left: 15px;
   flex: 0 0 45.77777777%;
   max-width: 45.77777777%;
}
.col-65 {
   position: relative;
   width: 100%;
   padding-right: 15px;
   padding-left: 15px;
   flex: 0 0 54.22222222%;
   max-width: 54.22222222%;
}

@media (min-width: 1400px){
   .col-xxl-1 {

   }
   .col-xxl-2 {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
   }
   .col-xxl-3 {
      flex: 0 0 25%;
      max-width: 25%;
   }
   .col-xxl-4 {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
   }
   .col-xxl-5 {

   }
   .col-xxl-6 {
      flex: 0 0 50%;
      max-width: 50%;
   }
   .col-xxl-7 {

   }
   .col-xxl-8 {
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%;
   }
   .col-xxl-9 {

   }
   .col-xxl-10 {

   }
   .col-xxl-11 {

   }
   .col-xxl-12 {
      flex: 0 0 100%;
      max-width: 100%;
   }
}

@media (max-width: 576px){
   .main .container-fluid {
      padding: 0 15px;
   }
 }

 .la-ball-clip-rotate-multiple>div {
   border-color: rgba(255, 255, 255, 0.18);
 }

 ngx-spinner > div > div:first-child::after {
   content: ' ';
   background-image: url('../assets/img/brand/logo2.png');
   background-size: 72px 73px;
   position: absolute;
   width: 72px;
   height: 72px;
   left: 12px;
   top: 12px;
   z-index: 99999;
}