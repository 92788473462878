/*
bootstrap table theme
*/
datatable-scroller {
  width: 100% !important;
}

.site-pokerstarts-es {
    color: white;
    background-color: #541709;
    border: 1px solid #5e1c0d;
  }
  
  .site-pokerstars {
    color: white;
    background-color: #8c2711;
    border: 1px solid #962d15;
  }
  
  .site-888poker {
    color: white;
    background-color: #111796;
    border: 1px solid #161d9b;
  }

  .site-888poker-pt {
    color: white;
    background-color: #171eb1;
    border: 1px solid #1c24b6;
  }
  
  .site-gg-network {
    color: white;
    background-color: #5e4936;
    border: 1px solid #644f3c;
  }
  
  .site-party-poker {
    color: black;
    background-color: #cc6b10;
    border: 1px solid #d37116;
  }
  
  .site-wpn-network {
    color: white;
    background-color: #363636;
    border: 1px solid #3a3a3a;
  }
  
  .site-bodog {
    color: white;
    background-color: #000000;
    border: 1px solid #050505;
  }
    
  .site-winamax {
    color: black;
    background-color: #4797c9;
    border: 1px solid #51a2d4;
  }
  
  .site-ipoker {
    color: black;
    background-color: #3b944d;
    border: 1px solid #3f9b51;
  }
    
  .site-chico {
    color: white;
    background-color: #0061ae;
    border: 1px solid #0466b6;
  }

  .site-wpt-global {
    color: white;
    background-color: #1f4e7a;
    border: 1px solid #18446d;
  }

  .site-suprema {
    color: white;
    background-color: #bea188;
    border: 1px solid #a1846b;
  }

  .site-coin-poker {
    color: white;
    background-color: #c45261;
    border: 1px solid #b94857;
  }

  .disabled-schedule datatable-body-cell:not(:first-child) {
    opacity: 0.6;
    text-decoration: line-through;
  }

  .disabled-tournament datatable-body-cell:not(:first-child) {
    opacity: 0.5;
  }

  .late-reg-closing {
    background-color: rgb(255, 121, 121);
  }

  .late-reg-closing-near {
    background-color: rgb(255, 105, 105);
  }

  .red-blind {
    background-color: rgb(245, 95, 95);
    border: 1px solid rgb(243, 108, 108);
  }

  .list-group-item-info {
    color: #23282c !important;
  }

  .datatable-row-even.list-group-item-info {
    background-color: #d3eef6 !important;
  }

  .datatable-row-odd.list-group-item-info {
    background-color: #c2e1eb !important;
  }

  .list-group-item-info:hover {
    background-color: #b0d6e2 !important;
    color: var(--text-body) !important;
  }

  .list-group-item-info .btn-edit-icon, .list-group-item-info .btn-outline-secondary {
    color: #23282c !important;
    border-color: #23282c !important;
  }

  .list-item-playing {
    color: #23282c !important;
  }

  .datatable-row-even.list-item-grouped{
    background-color: transparent !important;
  }

  .datatable-row-odd.list-item-grouped{
    background-color: transparent !important;
  }

  .datatable-row-even.list-item-playing{
    background-color: #bee5c7 !important;
  }

  .datatable-row-odd.list-item-playing{
    background-color: #aaddb6 !important;
  }

  .list-item-playing:hover {
    background-color: #9dd8ab !important;
    color: var(--text-body) !important;
  }

  .list-item-playing .btn-edit-icon, .list-item-playing .btn-outline-secondary {
    color: #23282c !important;
    border-color: #23282c !important;
  }

  .checked-row {
    box-shadow: inset 63px 0px 52px -56px #45FFAD;
  }

.ngx-datatable.bootstrap {
    box-shadow: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
    line-height: 1.5;
    white-space: nowrap;
    overflow-y: hidden;
    //min-height: 500px;
    .datatable-header {
      //height: unset !important;
      font-weight: bold;
      .datatable-header-cell {
        border-left: 1px solid var(--table-header-borders);
        vertical-align: bottom;
        padding: 0.5rem 0.20rem 0 0.20rem;
        margin: 0.65rem 0;
        &:first-child {
          padding: 1.15rem 0.45rem 0.65rem 0.45rem;
          border-left: none;
          margin: 0;
        }
        .datatable-header-cell-label {
          line-height: 24px;
          -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
          i {
            margin-left: 4px;
            margin-right: 4px;
            color: darkgray;
            font-size: 16px;
            &.absolute-left{
              position: absolute;
              top: 0.15rem;
              left: 0.25rem;
              &:hover{
                background-color: var(--body);
              }
            }
          }
          
        }
        &:active:not(:first-child) {
            background-color: var(--body);
            z-index: 1;
        }
      }
    }
    .datatable-body {
      .datatable-body-row {
        vertical-align: top;
        background-size: 300% 100%;
        background-image: transparent;
        &.datatable-row-even {
          background-color: var(--table-strips);
        }
        &.active {
          background-color: #1483ff;
          color: #fff;
        }
        &:hover{
          border-radius: 0.2rem;
          background-position: 100% 0;
          background-image: var(--gradient-hover);
        }
        //padding-left: 0.7rem;
        .datatable-body-cell {
          padding: 0.75rem 0.20rem;
          text-align: left;
          vertical-align: top;
          &:first-child {
            padding: 0.75rem 0.45rem;
          }
        }
      }
      .empty-row {
        $alert-padding-y: 0.75rem !default;
        $alert-padding-x: 1.25rem !default;
        position: relative;
        padding: $alert-padding-y $alert-padding-x;
        margin-bottom: 0;
      }
    }
    .datatable-footer {
      background: #424242;
      color: #ededed;
      margin-top: -1px;
      .page-count {
        line-height: 50px;
        height: 50px;
        padding: 0 1.2rem;
      }
      .datatable-pager {
        margin: 0 10px;
        vertical-align: top;
        ul {
          li {
            margin: 10px 0px;
            &:not(.disabled) {
              &.active,
              &:hover {
                a {
                  background-color: #545454;
                  font-weight: bold;
                }
              }
            }
          }
        }
        a {
          height: 22px;
          min-width: 24px;
          line-height: 22px;
          padding: 0;
          border-radius: 3px;
          margin: 0 3px;
          text-align: center;
          vertical-align: top;
          text-decoration: none;
          vertical-align: bottom;
          color: #ededed;
        }
        .datatable-icon-left,
        .datatable-icon-skip,
        .datatable-icon-right,
        .datatable-icon-prev {
          font-size: 18px;
          line-height: 27px;
          padding: 0 3px;
        }
      }
    }
    // Summary row styles
    .datatable-summary-row {
      .datatable-body-row {
        .datatable-body-cell {
          font-weight: bold;
        }
      }
    }
  }

  .ngx-datatable.bootstrap.embedded-table .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 0.35rem 0.20rem;
  }
  